import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReservationDataContext } from "../context/reservationDataContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";

export default function Policy() {
  const { i18n } = useTranslation();
  const { nextStep } = useContext(ReservationNavigationContext);
  const [currLanguageObject, setCurrLanguageObject] = useState({});
  const { t } = useTranslation();
  const { policyStepTranslations } = useContext(
    ReservationDataContext
  );

  useEffect(() => {
    const languages = [{language: "English", code: 'en'}, {language: "Hrvatski", code: 'hr'}];
    setCurrLanguageObject(languages.find(x => x.code === i18n.language));
  }, [i18n.language])

  return (
    <div className="final-container">
      <p className="medium-p" style={{maxWidth: '20rem'}}>
        {t("PolicyStep.Title")}
      </p>
      <p style={{textAlign: 'justify', maxWidth: '400px'}}>
        {
          policyStepTranslations.find(x => x.language === currLanguageObject.language) !== undefined ? 
          policyStepTranslations.find(x => x.language === currLanguageObject.language).text
            : null
        }
        </p>
      <div className="page">
        <div className="wrapper">
            <button className="next-button" onClick={() => { nextStep() }}>
                {t("PolicyStep.Button")}
            </button>
        </div>
      </div>
    </div>
  );
}