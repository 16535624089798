import ActivitiesStep from './activities-step.json';
import Confirmation from './confirmation.json';
import Cancelation from './cancelation.json';
import DatesStep from './dates-step.json';
import FinalStep from './final-step.json';
import Locations from './locations.json';
import Main from './main.json';
import NotFound from './not-found.json';
import NumberOfGuestsStep from './number-of-guests-step.json';
import ReservationDetails from './reservation-details.json';
import RoomsStep from './rooms-step.json';
import TimeStep from './time-step.json';
import UnitsStep from './units-step.json';
import UserDataStep from './user-data-step.json';
import PolicyStep from './policy-step.json';
import PaymentRequired from './payment-required.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...ActivitiesStep,
  ...Cancelation,
  ...Confirmation,
  ...DatesStep,
  ...FinalStep,
  ...Locations,
  ...Main,
  ...NotFound,
  ...NumberOfGuestsStep,
  ...PaymentRequired,
  ...ReservationDetails,
  ...RoomsStep,
  ...TimeStep,
  ...UnitsStep,
  ...PolicyStep,
  ...UserDataStep
};
