import { useContext, useState } from "react";
import Time from "../steps/Time";
import Dates from "../steps/Dates";
import Final from "../steps/Final";
import Units from "../steps/Units";
import UserData from "../steps/UserData";
import Activities from "../steps/Activities";
import NumberOfGuests from "../steps/NumberOfGuests";
import Rooms from "../steps/Rooms";
import * as crs from "../constants/reservationSteps";
import VideoStory from '../components/VideoStory';

import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useRef } from "react";
import { useEffect } from "react";
import { ReservationDataContext } from "../context/reservationDataContext";
import Questionnaire from "../steps/Questionnaire";
import Policy from "../steps/Policy";

const storyVideoSeedData = {
  isStoryVideoOpen: false,
  videoUrl: '',
  imageUrl: '',
  activityName: ''
};

export default function OnlineReservations() {
  const [storyVideoData, setStoryVideoData] = useState(storyVideoSeedData);
  const { logoPath, locationName } = useContext(ReservationDataContext);

  const { step, setTitle } = useContext(ReservationNavigationContext);
  const containerRef = useRef();

  const toggleVideoStoryModal = (activity) => {
    setStoryVideoData((prev) => ({
      ...prev,
      activityName: activity.name,
      videoUrl: activity.videoPath,
      imageUrl: logoPath,
      isStoryVideoOpen: !prev.isStoryVideoOpen
    }));
  }

  const resetVideoStoryToDefault = () => {
    setStoryVideoData(storyVideoSeedData);
  }

  const stepComponents = {
    [crs.USER_DATA_STEP]: <UserData />,
    [crs.UNITS_STEP]: <Units />,
    [crs.ACTIVITIES_STEP]: <Activities toggleVideoStoryModal={toggleVideoStoryModal}/>,
    [crs.NUMBER_OF_GUESTS_STEP]: <NumberOfGuests />,
    [crs.ROOMS_STEP]: <Rooms />,
    [crs.DATE_STEP]: <Dates />,
    [crs.TIME_STEP]: <Time />,
    [crs.FINAL_STEP]: <Final />,
    [crs.QUESTIONNAIRE_STEP]: <Questionnaire />,
    [crs.POLICY_STEP]: <Policy />,
  };

  useEffect(() => {
    setTitle(locationName);
  
    if (containerRef.current) {
      containerRef.current.animate(
        [
          { transform: "translateY(-30px)", opacity: 0 },
          { transform: "translateY(0)", opacity: 1 },
        ],
        {
          fill: "both",
          easing: "ease-out",
          duration: 300,
        }
      );
    }
  }, [step?.id, locationName, setTitle]);

  return (
    <>
      <div className="styled-steps" ref={containerRef}>
        {stepComponents[step?.id]}
      </div>
      
        <VideoStory storyVideoData={storyVideoData}
          resetVideoStoryToDefault={resetVideoStoryToDefault}  />
    </>
  );
}
